import React from "react"
import { Content, Layout, Heading1, Heading2, SEO, Changelog } from "../components"
import styled from "styled-components"
import { Link } from "gatsby"

import eyeDropper from "../assets/eye-dropper.svg"

const EyeDropper = styled.img`
  background-color: silver;
  border-radius: 5px;
  padding: 2px;
`

const Tip = styled.div`
  border-left: 0.5rem solid ${(props) => props.theme.colors.secondaryAlt};
  color: ${(props) => props.theme.colors.secondaryAlt};
  border-radius: 0 10px 10px 0;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  max-width: 90%;

  a {
    color: ${(props) => props.theme.colors.secondary};
  }
`

const TipTitle = styled.h3`
  color: ${(props) => props.theme.colors.secondaryAlt};
  margin-top: 0px;
`

const Installed = () => {
  return (
    <Layout>
      <SEO title="Welcome to Eye Dropper" />
      <Content>
        <Heading1>Welcome to Eye Dropper</Heading1>
        <Tip>
          <TipTitle>I know its too early, you just installed the thing...</TipTitle>
          <div>
            But in case you struggle with anything later, find a bug or have an idea how to improve
            something, please{" "}
            <a href="https://github.com/kepi/chromeEyeDropper/issues">let me know</a>.
          </div>
        </Tip>

        <Heading2>How to use it?</Heading2>

        <p>
          Just hit the <strong>Eye Dropper</strong> icon{" "}
          <EyeDropper
            src={eyeDropper}
            alt="Icon showing Eye Dropper with color dropping from it."
            width="24px"
          />{" "}
          in the extension area when you want to pick some color.
        </p>

        <Heading2>What's new?</Heading2>
        <p>
          Here are changes from latest 3 versions or you can check complete{" "}
          <Link to="/changelog">change log</Link>.
        </p>
        <Changelog versions={3} />
      </Content>
    </Layout>
  )
}

export default Installed
